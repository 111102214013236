(function () {
    'use strict';

    /*
     * burger menu
     */
    var $toggleButton = $('#app-toggle-mobile-menu');
    var $navigation = $('#app-navigation');
    var isOpen = false;
    $toggleButton.on('click', function () {
        if(!isOpen) {
            $navigation.css({
                visibility: 'hidden',
                height: 'auto'
            });
            var originalHeight = $navigation.outerHeight();
            $navigation.removeAttr('style');

            //force repaint
            $navigation[0].offsetHeight; // jshint ignore:line

            $navigation.css({
                height: originalHeight
            });
            isOpen = true;
        }
        else {
            $navigation.css({
                height: 0
            });
            isOpen = false;
        }
    });


    /*
     * header visual
     */
    var $priceContents = $('#app-visual .price-content');
    $('[data-open-price]').on('click', function (evt) {
        var $target = $('#app-visual .price-content.is-' + $(this).data('openPrice'));
        evt.stopPropagation();

        $priceContents.removeClass('is-open');
        $target.addClass('is-open');

        $target.find('.close-button').one('click', function () {
            $target.removeClass('is-open');
        });
    });

})();