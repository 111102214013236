(function () {
    'use strict';

    var $root = $('#view-contest');
    if(!$root.length) {
        return;
    }

    var $thermometer = $('#contest-thermometer');
    var $thermometerContainer = $('#thermometer-container');

    //make thermometer sticky
    //see https://github.com/soenkekluth/sticky-state
    new StickyState($thermometer[0]);

    //make YT videos responsive
    $('.hint-content.is-youtube').fitVids();

    //toggle hints
    $root.find('[data-hook="hint-button"]').on('click', function () {
        $(this).parents('[data-hook="hint"]').toggleClass('is-open');
    });

    //highlight answers
    var processAnswers = function(answer, validateEmpty) {
        var userInput = String($(answer).val());
        var correctAnswer = String($(answer).data('answer'));
        var isCorrect = userInput.toLowerCase() === correctAnswer.toLowerCase();

        if (userInput.length === 0 && validateEmpty || userInput.length > 0) {
            $(answer).siblings('[data-hook="answer-result"]')
                .removeClass('is-correct')
                .removeClass('is-wrong')
                .addClass(isCorrect ? 'is-correct' : 'is-wrong');


            var correctCount = $root.find('[data-hook="answer-result"].is-correct').length;
            var stage = Math.floor(1 / 2 * correctCount);

            $thermometer
                .removeClass('is-stage-0')
                .removeClass('is-stage-1')
                .removeClass('is-stage-2')
                .removeClass('is-stage-3')
                .addClass('is-stage-' + stage);
        }
    };

    $root.find('[data-hook="answer-input"]').each(function () {
        processAnswers(this, false);
    });

    //Position thermometer absolute when keyboard opens
    var containerPos = $thermometerContainer.offset().top;
    var thermometerHeight = $thermometer.outerHeight();
    $root
        .find('[data-hook="answer-input"]')
        .on('focus', function () {
            var questionPos = $(this).parents('li').offset().top;

            var calcTop = questionPos - containerPos - parseInt($thermometer.css('paddingTop'));
            var maxTop = $thermometerContainer.height() - thermometerHeight;
            var top = Math.min(calcTop, maxTop);

            $thermometer.css({
                top: top,
                height: thermometerHeight
            })
            .addClass('fixedfix');
        })
        .on('blur', function () {
            $thermometer.css({
                top: '',
                height: ''
            })
            .removeClass('fixedfix');
        });

    $root.find('[data-hook="answer-input"]').on('change', function () {
        processAnswers(this, true);
    });

    //validate form before submission
    $('#contest-form').on('submit', function (evt) {
        var $emptyInputs = $root.find('[data-hook="answer-input"]').filter(function (idx, elem) {
            return $(this).val() === '';
        });

        //if($emptyInputs.length) {
        //    evt.preventDefault();

            $emptyInputs.each(function () {
                $(this).parents('[data-hook="answer"]').addClass('is-invalid');
            });
        //}
    });

})();
